/* Vendors */
/*! fluidvids.js v2.4.1 | (c) 2014 @toddmotto | https://github.com/toddmotto/fluidvids */
!function(e,t){"function"==typeof define&&define.amd?define(t):"object"==typeof exports?module.exports=t:e.fluidvids=t()}(this,function(){"use strict";var e={selector:["iframe"],players:["www.youtube.com","player.vimeo.com"]},t=[".fluidvids {","width: 100%; max-width: 100%; position: relative;","}",".fluidvids-item {","position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;","}"].join(""),i=document.head||document.getElementsByTagName("head")[0],n=function(t){return new RegExp("^(https?:)?//(?:"+e.players.join("|")+").*$","i").test(t)},d=function(e,t){return parseInt(e,10)/parseInt(t,10)*100+"%"},o=function(e){if(n(e.src)&&!e.getAttribute("data-fluidvids")){var t=document.createElement("div");e.parentNode.insertBefore(t,e),e.className+=(e.className?" ":"")+"fluidvids-item",e.setAttribute("data-fluidvids","loaded"),t.className+="fluidvids",t.style.paddingTop=d(e.height,e.width),t.appendChild(e)}},r=function(){var e=document.createElement("div");e.innerHTML="<p>x</p><style>"+t+"</style>",i.appendChild(e.childNodes[1])};return e.render=function(){for(var t=document.querySelectorAll(e.selector.join()),i=t.length;i--;)o(t[i])},e.init=function(t){for(var i in t)e[i]=t[i];e.render(),r()},e});

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // Offset fixed header height
        var mq = window.matchMedia( "(max-width: 36.9230769231em)" );
        if (mq.matches) {
          var offset = $(".device-header").height();
          document.body.style.paddingTop = offset + 'px';
        }

        // Menu toggle menu
        $('.menu_toggle').click(function(event) {
          $('.nav-primary').toggleClass('open');
        });

        // Fluid vids
        fluidvids.init({
          selector: ['iframe', 'object'], // runs querySelectorAll()
          players: ['www.youtube.com', 'player.vimeo.com'] // players to support
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
